import React, { useEffect } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import URLSearchParams from "url-search-params";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import * as locales from '@material-ui/core/locale';
import "assets/vendors/style";
import indigoTheme from "./themes/indigoTheme";
import cyanTheme from "./themes/cyanTheme";
import orangeTheme from "./themes/orangeTheme";
import amberTheme from "./themes/amberTheme";
import pinkTheme from "./themes/pinkTheme";
import blueTheme from "./themes/blueTheme";
import purpleTheme from "./themes/purpleTheme";
import greenTheme from "./themes/greenTheme";
import AppLocale from "../lngProvider";
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK,
  DARK_AQUA
} from "constants/ThemeColors";
import SignIn from "./SignIn";
import ForgotPassword from "./ForgotPassword";
import { setInitUrl } from "../actions/Auth";
import RTL from "util/RTL";
import { setDarkTheme, setThemeColor } from "../actions/Setting";
import AppLayout from "./AppLayout";
import { NoMatch } from '../app/routes/index'
import aquaTheme from "./themes/aquaTheme";

const RestrictedRoute = ({ component: Component, authUser, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location }
          }}
        />}
  />;

const App = (props) => {
  const dispatch = useDispatch();
  const { themeColor, darkTheme, locale, isDirectionRTL } = useSelector(({ settings }) => settings);
  const { authUser, initURL } = useSelector(({ auth }) => {
    return auth;
  });
  const isDarkTheme = darkTheme;
  const { match, location } = props;

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === '') {
      dispatch(setInitUrl(props.history.location.pathname));
    }
    const params = new URLSearchParams(props.location.search);
    if (params.has("theme-name")) {
      dispatch(setThemeColor(params.get('theme-name')));
      document.body.classList.add(params.get('theme-name'));
    } else {
      document.body.classList.add(themeColor);
    }
    if (params.has("dark-theme")) {
      dispatch(setDarkTheme());
    }
  }, [dispatch, initURL, props.history.location.pathname, props.location.search, themeColor]);


  const getColorTheme = (themeColor, applyTheme) => {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createTheme(indigoTheme, locales['frFR']);
        break;
      }
      case CYAN: {
        applyTheme = createTheme(cyanTheme, locales['frFR']);
        break;
      }
      case AMBER: {
        applyTheme = createTheme(amberTheme, locales['frFR']);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createTheme(orangeTheme, locales['frFR']);
        break;
      }
      case PINK: {
        applyTheme = createTheme(pinkTheme, locales['frFR']);
        break;
      }
      case BLUE: {
        applyTheme = createTheme(blueTheme, locales['frFR']);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createTheme(purpleTheme, locales['frFR']);
        break;
      }
      case GREEN: {
        applyTheme = createTheme(greenTheme, locales['frFR']);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createTheme({ ...indigoTheme, direction: 'rtl' }, locales['frFR']);
        break;
      }
      case DARK_AQUA: {
        applyTheme = createTheme({ ...aquaTheme, direction: 'rtl' }, locales['frFR']);
        break;
      }
      case DARK_CYAN: {
        applyTheme = createTheme(cyanTheme, locales['frFR']);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createTheme(amberTheme, locales['frFR']);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createTheme(orangeTheme, locales['frFR']);
        break;
      }
      case DARK_PINK: {
        applyTheme = createTheme(pinkTheme, locales['frFR']);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createTheme(blueTheme, locales['frFR']);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createTheme(purpleTheme, locales['frFR']);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createTheme(greenTheme, locales['frFR']);
        break;
      }
      default:
        createTheme(aquaTheme, locales['frFR']);
    }
    return applyTheme;
  };

  let applyTheme = createTheme(aquaTheme, locales['frFR']);
  if (isDarkTheme) {
    document.body.classList.add('dark-theme');
    applyTheme = createTheme(darkTheme, locales['frFR'])
  } else {
    applyTheme = getColorTheme(themeColor, applyTheme);
  }
  if (location.pathname === '/') {
    if (authUser === null) {
      return (<Redirect to={'/signin'} />);
    } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
      return (<Redirect to={'/app/locations'} />);
    } else {
      return (<Redirect to={initURL} />);
    }
  }
  if (isDirectionRTL) {
    applyTheme.direction = 'rtl';
    document.body.classList.add('rtl')
  } else {
    document.body.classList.remove('rtl');
    applyTheme.direction = 'ltr';
  }

  const currentAppLocale = AppLocale[locale.locale];
  return (
    <MuiThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <RTL>
            <div className="app-main">
              <Switch>
                <RestrictedRoute path={`${match.url}app`} authUser={authUser}
                  component={AppLayout} />
                <Route path='/signin' component={SignIn} />
                <Route path='/forgotpassword' component={ForgotPassword}/>
                {/*<Route*/}
                {/*  component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>*/}
                <Route path="*">
                  <NoMatch />
                </Route>
              </Switch>
            </div>
          </RTL>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};


export default App;
