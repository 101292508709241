import React from 'react';
import { version } from '../../../package.json'

const Footer = () => {
  return (
    <footer className="app-footer">
      <span className="d-inline-block">Copyright DemandSide &copy; {(new Date().getFullYear())} - Version {version}</span>
      <a className="d-inline-block"
        href='https://aliaterra.fr/pdc-aliaterra/'
        target='_blank'
        rel="noopener noreferrer"
      >
        Politique de confidentialité des données
      </a>
      <a className="d-inline-block"
        href='https://aliaterra.fr/cgu-aliaterra/'
        target='_blank'
        rel="noopener noreferrer"
      >
        Conditions générales d'utilisation
      </a>
    </footer>
  );
}
  ;

export default Footer;
