import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { isString } from 'lodash'
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar'
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import { userSignOut } from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        paper: {
            width: '80%',
            maxHeight: 435,
        },
    }),
);

const LogoutConfirmation = (props) => {
    const { onClose, keepMounted, open, classes } = props;

    const handleClose = (disconnect) => {
        onClose(disconnect)
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="logut-title"
            open={open}
            keepMounted={keepMounted}
            classes={classes}
        >
            <DialogTitle id="logout-title">Déconnexion ?</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Êtes-vous sûr de vouloir vous déconnecter ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)}>
                    Annuler
                </Button>
                <Button onClick={() => handleClose(true)} color="secondary" autoFocus>
                    Déconnexion
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const UserInfo = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [anchorE1, setAnchorE1] = useState(null);
    const [open, setOpen] = useState(false);
    const [openLogoutConfirmation, setOpenLogoutConfirmation] = useState(false);

    const handleClick = event => {
        setOpen(true);
        setAnchorE1(event.currentTarget);
    };

    const handleRequestClose = () => {
        setOpen(false);
    };

    const email = useSelector(({ firebase }) => {
        return firebase.auth.email;
    });

    const profile = useSelector(({ firebase }) => {
        return firebase.profile;
    });

    const handleOpenLogoutConfirmation = () => {
        setOpenLogoutConfirmation(true);
    };

    const handleCloseLogoutConfirmation = (disconnect) => {
        setOpenLogoutConfirmation(false);
        if (disconnect) dispatch(userSignOut());
    };

    return (
        <div >
            {/* <Link className="app-logo mr-2 d-none d-sm-block" to="/">
        <img src={require("assets/images/logo.png")} />
      </Link> */}

            <div className="user-profile d-flex flex-row align-items-center">
                <Avatar className="user-avatar ">
                    {profile && profile.firstName && profile.firstName.charAt(0)}
                    {profile && profile.lastName && profile.lastName.charAt(0)}
                </Avatar>
                <div className="user-detail">
                    <h4
                        className="user-name d-flex"
                        onClick={handleClick}
                    >
                        <span className='text-truncate'>
                            {
                                profile && profile.firstName && profile.lastName
                                    ? `${profile.firstName} ${profile.lastName}`
                                    : isString(email) ? email.split('@')[0] : '-'
                            }
                        </span>
                        <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
                    </h4>
                </div>
                <Menu className="user-info"
                    id="simple-menu"
                    anchorEl={anchorE1}
                    open={open}
                    onClose={handleRequestClose}
                    PaperProps={{
                        style: {
                            minWidth: 120,
                            paddingTop: 0,
                            paddingBottom: 0
                        }
                    }}
                >
                    <MenuItem onClick={() => {
                        handleRequestClose()
                        history.push("/app/profile");
                    }}>
                        <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
                        <IntlMessages id="popup.profile" />
                    </MenuItem>
                    {/* <MenuItem onClick={handleRequestClose}>
                        <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
                        <IntlMessages id="popup.setting"/>
                    </MenuItem> */}
                    <MenuItem onClick={() => {
                        handleRequestClose();
                        handleOpenLogoutConfirmation();
                    }}>
                        <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

                        <IntlMessages id="popup.logout" />
                    </MenuItem>
                </Menu>
            </div>
            <LogoutConfirmation
                classes={{
                    paper: classes.paper,
                }}
                keepMounted
                open={openLogoutConfirmation}
                onClose={handleCloseLogoutConfirmation}
            />
        </div>
    );
};

export default UserInfo;


