import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import { has } from 'lodash'
import { Container, CircularProgress } from '@material-ui/core'
import { userSignOut } from 'actions/Auth'
import asyncComponent from '../../util/asyncComponent'

export const NoMatch = () => {
  return (
    <div>
      <h3>Erreur 404. Page non trouvée</h3>
    </div>
  )
}

const Routes = ({ match }) => {
  const dispatch = useDispatch()
  const profile = useSelector(({ firebase }) => {
    return firebase.profile
  })

  const { role, applications } = profile
  const showTopoApp = has(applications, 'topology') && applications.topology
  const showAlertsApp = has(applications, 'topology') && applications.alerts
  const showAdminPage = role === 'admin'
  const showManagerPage = role === 'manager'

  if (!isLoaded(profile)) {
    return (
      <Container style={{ textAlign: 'center', paddingTop: 160 }}>
        <CircularProgress color="secondary" />
      </Container>
    )
  }
  if (isEmpty(profile)) {
    dispatch(userSignOut())
    return null
  }

  return (
    <Switch>
      <Route
        path={`${match.url}/profile`}
        component={asyncComponent(() => import('./ProfilePage/index'))}
      />

      {showAdminPage && (
        <Route
          path={`${match.url}/organisations/create`}
          component={asyncComponent(() => import('./CreateOrganisationPage/index'))}
        />
      )}
      {(showAdminPage || showManagerPage) && (
        <Route
          path={`${match.url}/organisations/:id/edit`}
          component={asyncComponent(() => import('./CreateOrganisationPage/index'))}
        />
      )}
      {showAdminPage && (
        <Route
          path={`${match.url}/organisations`}
          component={asyncComponent(() => import('./OrganisationsPage/index'))}
        />
      )}
      {showAdminPage && (
        <Route
          path={`${match.url}/users/create`}
          component={asyncComponent(() => import('./CreateUserPage/index'))}
        />
      )}
      {showAdminPage && (
        <Route
          path={`${match.url}/users/:id/edit`}
          component={asyncComponent(() => import('./CreateUserPage/index'))}
        />
      )}
      {showAdminPage && (
        <Route
          path={`${match.url}/users`}
          component={asyncComponent(() => import('./UsersPage/index'))}
        />
      )}
      {showAdminPage && (
        <Route
          path={`${match.url}/devices/create`}
          component={asyncComponent(() => import('./CreateDevicePage/index'))}
        />
      )}
      {(showAdminPage || showManagerPage) && (
        <Route
          path={`${match.url}/locations/create`}
          component={asyncComponent(() => import('./CreateLocationPage/index'))}
        />
      )}
      {(showAdminPage || showManagerPage) && (
        <Route
          path={`${match.url}/locations/:id/edit`}
          component={asyncComponent(() => import('./CreateLocationPage/index'))}
        />
      )}
      {(showAdminPage || showManagerPage) && (
        <Route
          path={`${match.url}/locations/:id/analyse`}
          component={asyncComponent(() => import('./AnalyseLocationPage/index'))}
        />
      )}
      {(showAdminPage || showManagerPage) && (
        <Route
          path={`${match.url}/locations/:id/auto`}
          component={asyncComponent(() => import('./Auto/index'))}
        />
      )}
      {(showAdminPage || showManagerPage) && (
        <Route
          path={`${match.url}/locations/:id/editAuto/:idAuto`}
          component={asyncComponent(() => import('./Auto/index'))}
        />
      )}
      {showTopoApp && (
        <Route
          path={`${match.url}/locations/:id/map`}
          component={asyncComponent(() => import('./Map'))}
        />
      )}
      {showTopoApp && (
        <Route
          path={`${match.url}/locations/:id`}
          component={asyncComponent(() => import('./Topology/LocationPage'))}
        />
      )}
      {showTopoApp && (
        <Route
          path={`${match.url}/locations`}
          component={asyncComponent(() => import('./Topology/LocationsPage'))}
        />
      )}
      {showTopoApp && (
        <Route
          path={`${match.url}/devices/:id/schedule/manual`}
          component={asyncComponent(() => import('./ManualSchedulePage'))}
        />
      )}
      {showTopoApp && (
        <Route
          path={`${match.url}/devices/:id/history`}
          component={asyncComponent(() => import('./HistoryPage'))}
        />
      )}
      {showTopoApp && (
        <Route
          path={`${match.url}/devices/:id/exports/history`}
          component={asyncComponent(() => import('./Exports/ExportsListPage/index'))}
        />
      )}
      {showTopoApp && (
        <Route
          path={`${match.url}/devices/:id`}
          component={asyncComponent(() => import('./Topology/DevicePage'))}
        />
      )}
      {showTopoApp && (
        <Route
          path={`${match.url}/devices`}
          component={asyncComponent(() => import('./Topology/DevicesPage'))}
        />
      )}

      {showAlertsApp && (
        <Route
          path={`${match.url}/alerts/add`}
          component={asyncComponent(() => import('./Alerts/CreateAlertPage/index'))}
        />
      )}
      {showAlertsApp && (
        <Route
          path={`${match.url}/alerts/:id/edit`}
          component={asyncComponent(() => import('./Alerts/CreateAlertPage/index'))}
        />
      )}
      {showAlertsApp && (
        <Route
          path={`${match.url}/alerts/history`}
          component={asyncComponent(() => import('./Alerts/HistoryAlertsPage/index'))}
        />
      )}
      {showAlertsApp && (
        <Route
          path={`${match.url}/alerts`}
          component={asyncComponent(() => import('./Alerts/AlertsPage/index'))}
        />
      )}
      {showAlertsApp && (
        <Route
          path={`${match.url}/profiles`}
          component={asyncComponent(() => import('./ProfilesPage/index'))}
        />
      )}
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  )
}

export default withRouter(Routes)
