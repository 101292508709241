export default {
    palette: {
        primary: {
            light: '#56668e',
            main: '#2c4072',
            dark: '#23335b',
            contrastText: '#fff'
        },
        secondary: {
            light: '#6ad5b6',
            main: '#46cba4',
            dark: '#38a283',
            contrastText: '#fff'
        }
    },
    status: {
        danger: '#eb5965',
    },
    typography: {
        button: {
            fontWeight: 400,
            textAlign: 'capitalize'
        },
    },
};
