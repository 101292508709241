import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Common from "./Common";


export default (history, firebaseReducer, firestoreReducer) => combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    firebase: firebaseReducer,
    firestore: firestoreReducer
});
