import {SET_FLASH_MESSAGE, TOGGLE_COLLAPSED_NAV} from 'constants/ActionTypes';
import { isNil } from 'lodash';

const initialSettings = {
  navCollapsed: false
};

const settings = (state = initialSettings, action) => {
	switch (action.type) {
		case '@@router/LOCATION_CHANGE':
			return {
				...state,
				navCollapsed: false
			};
		case TOGGLE_COLLAPSED_NAV:
			return {
				...state,
				navCollapsed: action.isNavCollapsed
			};
    case SET_FLASH_MESSAGE:
      const { flashMessage } = action;
      return {
        ...state,
        flashMessage: isNil(flashMessage) ? null : {
          type: flashMessage.type,
          message: flashMessage.message
        }
      }
		default:
			return state;
	}
};

export default settings;
