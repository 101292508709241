import React, { useEffect, useState } from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";
import { useSelector } from 'react-redux'
import { version } from '../../../package.json'

const SideBarContent = () => {
  const [navigationMenus, setNavigationMenus] = useState([]);
  const { role, applications } = useSelector(({ firebase })  => {
    return firebase.profile;
  });

  useEffect(() => {
    if (!applications) return
    const menuItems = [];
    if(applications.topology) {
      const topoItems = {
        name: 'sidebar.section.topology',
        type: 'section',
        children: []
      };

      topoItems.children.push({
        name: 'pages.locations',
        type: 'item',
        link: '/app/locations',
        icon: 'pin'
      });
      topoItems.children.push({
        name: 'pages.devices',
        type: 'item',
        link: '/app/devices',
        icon: 'devices'
      });
      menuItems.push(topoItems)
    }
  
  if(applications.alerts) {
    const alertsItems = {
      name: 'sidebar.section.alerts',
      type: 'section',
      children: []
    };

    alertsItems.children.push({
      name: 'pages.alerts',
      type: 'item',
      link: '/app/alerts',
      icon: 'notifications'
    });

    alertsItems.children.push({
      name: 'pages.alertsHistory',
      type: 'item',
      link: '/app/alerts/history',
      icon: 'time-restore'
    });
    menuItems.push(alertsItems)
  }

  if (role === 'admin') {
    const adminItems = {
      name: 'sidebar.section.configuration',
      type: 'section',
      children: []
    };

    adminItems.children.push({
      name: 'sidebar.section.locations',
      type: 'collapse',
      children:[
        {
          name: 'pages.newLocations',
          type: 'item',
          link: '/app/locations/create',
          icon: 'plus-circle'
        }
      ],
      icon: 'pin'
    });

    adminItems.children.push({
      name: 'sidebar.section.organisations',
      type: 'collapse',
      children:[
        {
          name: 'pages.organisations',
          type: 'item',
          link: '/app/organisations',
          icon: 'view-list-alt'
        },
        {
          name: 'pages.newLocations',
          type: 'item',
          link: '/app/organisations/create',
          icon: 'plus-circle'
        }
      ],
      icon: 'balance'
    });
    adminItems.children.push({
      name: 'sidebar.section.devices',
      type: 'collapse',
      children:[
        {
          name: 'pages.newLocations',
          type: 'item',
          link: '/app/devices/create',
          icon: 'plus-circle'
        },
      ],
      icon: 'devices'
    });
    adminItems.children.push({
      name: 'sidebar.section.profiles',
      type: 'collapse',
      children:[
        {
          name: 'pages.profiles',
          type: 'item',
          link: '/app/profiles',
          icon: 'view-list-alt'
        }
      ],
      icon: 'file'
    });
    adminItems.children.push({
      name: 'sidebar.section.users',
      type: 'collapse',
      children:[
        {
          name: 'pages.organisations',
          type: 'item',
          link: '/app/users',
          icon: 'view-list-alt'
        },
        {
          name: 'pages.newLocations',
          type: 'item',
          link: '/app/users/create',
          icon: 'plus-circle'
        }
      ],
      icon: 'accounts'
    });
    menuItems.push(adminItems)
  }

  setNavigationMenus(menuItems);
  }, [role, applications])

  return (
    <CustomScrollbars className=" scrollbar">
      {/* <Link className="app-logo mr-2 d-none d-sm-block" to="/">
        <img src={require("assets/images/logo.png")} />
      </Link> */}
      <Navigation menuItems={navigationMenus} />
      <div className="text-center position-absolute fixed-bottom">
        <small> Version {version}</small>
      </div>
    </CustomScrollbars>
  );
};

export default SideBarContent;
