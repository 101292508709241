import React from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { COLLAPSED_DRAWER, FIXED_DRAWER } from "constants/ActionTypes";
import { setFlashMessage, toggleCollapsedNav } from "actions/Setting";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import './styles.css'

const Index = (props) => {

  const dispatch = useDispatch();
  const { drawerType, navCollapsed } = useSelector(({ settings }) => settings);
  const flashMessage = useSelector((state) => state.common.flashMessage);

  const onToggleCollapsedNav = (e) => {
    const val = !navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  const drawerStyle = drawerType.includes(FIXED_DRAWER) ? "d-block d-xl-none" : drawerType.includes(COLLAPSED_DRAWER) ? "d-block" : "d-none";

  const closeFlashMessage = () => {
    dispatch(setFlashMessage(null))
  }

  return (
    <AppBar className="app-main-header">
      <Toolbar className="app-toolbar" disableGutters={false}>
        <IconButton
          className={`jr-menu-icon mr-3 ${drawerStyle}`}
          aria-label="Menu"
          onClick={onToggleCollapsedNav}
        >
          <span className="menu-icon" />
        </IconButton>

        {/*<Link className="app-logo mr-2 d-none d-sm-block" to="/">
          <img src={require("assets/images/logo.png")} alt='logo' />
        </Link>*/}

        {/* <SearchBox styleName="d-none d-lg-block" placeholder=""
          onChange={updateSearchText}
          value={searchText}
        /> */}

        {/* <ul className="header-notifications list-inline ml-auto">
          <li className="list-inline-item">
          </li>
          <li className="list-inline-item app-tour">
            <Dropdown
              className="quick-menu"
              isOpen={appNotification}
              toggle={onAppNotificationSelect}>

              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown">
                <IconButton className="icon-btn">
                  <i className="zmdi zmdi-notifications-none icon-alert animated infinite wobble" />
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right>
                <CardHeader styleName="align-items-center"
                  heading={<IntlMessages id="appNotification.title" />} />
                <AppNotification />
              </DropdownMenu>
            </Dropdown>
          </li>
          <li className="list-inline-item mail-tour">
          </li>

        </ul> */}

        { flashMessage?.message &&
          <Snackbar
            open={true}
            autoHideDuration={10000}
            onClose={closeFlashMessage}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              variant="filled"
              elevation={6}
              severity={flashMessage.type}
              onClose={closeFlashMessage}
            >
              { flashMessage.message }
            </Alert>
          </Snackbar>

        }
        <div className="ellipse-shape" />
      </Toolbar>
    </AppBar>
  );
};


export default withRouter(Index);
