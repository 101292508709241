import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

// // Initialize Firebase
firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()

const googleAuthProvider = firebase.auth.GoogleAuthProvider
const facebookAuthProvider = firebase.auth.FacebookAuthProvider
const githubAuthProvider = firebase.auth.GithubAuthProvider
const twitterAuthProvider = firebase.auth.TwitterAuthProvider

const firestore = firebase.firestore()
const functions = firebase.functions()
const storage = firebase.storage()

export {
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  firebaseConfig,
  firestore,
  firebase,
  functions,
  storage,
}
