import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, useHistory} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { useForm, Controller } from "react-hook-form";
import firebase from "firebase/app";
import { Alert } from "@material-ui/lab";

const ForgotPassword = () => {
  const [loader, setLoader] = useState(false);
  const { handleSubmit, errors, control } = useForm();
  const history = useHistory();

  const onSubmitSchedule = (props) => {
    const { email } = props.data;
    sendMail(email);
  };

  const sendMail = email => {
    setLoader(true);
    firebase.auth().sendPasswordResetEmail(email).then(() => {
      history.replace("/signin", { isSend: true });
    }).catch(() => {
      NotificationManager.error('Email invalide', 'Saisissez une adresse mail valide', 1000);
      setLoader(false)
    });
  }

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="logo">
            <img srcSet={`${require("assets/images/logo.png")} 8x`} alt='logo' />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header">
            <h1>Réinitialisation du mot de passe</h1>
          </div>

          <div className="app-login-form">
            <form onSubmit={handleSubmit((data) =>
                onSubmitSchedule({
                  data,
                })
              )}>
              <Controller
                  as={TextField}
                  fullWidth
                  name="email"
                  control={control}
                  defaultValue=""
                  label={<IntlMessages id="appModule.email" />}
                  margin="normal"
                  className="mt-1 my-sm-3"
                  rules={{
                    required: {
                      value: true,
                      message: "L' adresse email est obligatoire.",
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Le format de l'adresse email est invalide.",
                    },
                  }}
                />
                {errors.email && (
                  <Alert severity="error">{errors.email.message}</Alert>
                )}
              <div className="mb-3 d-flex align-items-center justify-content-between">
                <Button variant="contained" color="primary" type="submit">
                  Envoyer
                </Button>
                <Link to={{pathname: "/signin", state: { isSend: false } }}>
                  Se connecter
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {
        loader &&
        <div className="loader-view">
          <CircularProgress/>
        </div>
      }
      <NotificationContainer/>
    </div>
  )
};

export default ForgotPassword;
