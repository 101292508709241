import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import { hideMessage, showAuthLoader, userSignIn } from "actions/Auth";
import { useForm, Controller } from "react-hook-form";
import { Alert } from "@material-ui/lab";
import { isEmpty } from "lodash";
import {NotificationContainer, NotificationManager} from 'react-notifications';

const SignIn = (props) => {
  const [popupErrorMessage, setPopupErrorMessage] = useState(null);
  const dispatch = useDispatch();
  const { handleSubmit, errors, control } = useForm(); // initialize the hook
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const location = useLocation();

  useEffect(() => {
    if (location.state !== undefined) {
      if (location.state.isSend)
        NotificationManager.success('Email envoyé', "Veuillez suivre les instructions de l'email qui vous a été envoyé", 1000 );
    }
    if (showMessage) {
      setPopupErrorMessage(alertMessage);
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      props.history.push("/");
    }
  }, [showMessage, authUser, props.history, dispatch, alertMessage, location]);

  const onSubmitSchedule = (props) => {
    const { email, password } = props.data;
    dispatch(showAuthLoader());
    dispatch(userSignIn({ email, password }));
  };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div
        className="app-login-main-content"
        style={{ width: 600, height: "auto" }}
      >
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="logo">
            <img srcSet={`${require("assets/images/logo.png")} 8x`} alt='logo' />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>
              Connexion
            </h1>
          </div>

          <div className="app-login-form">
            <form
              onSubmit={handleSubmit((data) =>
                onSubmitSchedule({
                  data,
                })
              )}
            >
              <fieldset>
                <Controller
                  as={TextField}
                  fullWidth
                  name="email"
                  control={control}
                  defaultValue=""
                  label={<IntlMessages id="appModule.email" />}
                  margin="normal"
                  className="mt-1 my-sm-3"
                  rules={{
                    required: {
                      value: true,
                      message: "L' adresse email est obligatoire.",
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Le format de l'adresse email est invalide.",
                    },
                  }}
                />
                {errors.email && (
                  <Alert severity="error">{errors.email.message}</Alert>
                )}

                <Controller
                  type="password"
                  as={TextField}
                  fullWidth
                  name="password"
                  control={control}
                  defaultValue=""
                  label={<IntlMessages id="appModule.password" />}
                  margin="normal"
                  className="mt-1 my-sm-3"
                  rules={{
                    required: {
                      value: true,
                      message: "Le mot de passe est obligatoire.",
                    },
                  }}
                />
                {errors.password && (
                  <Alert severity="error">{errors.password.message}</Alert>
                )}

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    <IntlMessages id="appModule.signIn" />
                  </Button>

                  <Link to="/forgotpassword">
                    Mot de passe oublié ?
                  </Link>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}

      <Snackbar
        open={!isEmpty(popupErrorMessage)}
        onClose={() => setPopupErrorMessage(null)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          elevation={6}
          severity="error"
          onClose={() => setPopupErrorMessage(null)}
        >
          {popupErrorMessage}
        </Alert>
      </Snackbar>
      <NotificationContainer/>
    </div>
  );
};

export default SignIn;
